<template>
  <div class="info-prizes">
    <LcTabs v-if="tierTableProps" v-bind="{ tabs }" class="mb-lg">
      <template #tab="{ tab }">
        <div class="flex flex-col items-center justify-center text-center">
          <span :class="{ 'text-xs': !!tab.data.tierMultiplier }">
            {{
              tab.name === 'DEFAULT'
                ? $t(
                    `info.prizes.tiertab.default.${tierTableProps.lotteryType}`,
                  )
                : getTranslatedAddOnTitle(tab.data.addOnName)
            }}
          </span>

          <span v-if="tab.data.tierMultiplier">
            {{
              $t('tiertable.multiplier', {
                value: tab.data.tierMultiplier,
              })
            }}
          </span>
        </div>
      </template>

      <template #tab-panel="{ tab }">
        <TierTable
          v-bind="tierTableProps"
          :grouped-prize-tiers="getGroupedPrizeTiers(tab.data.prizeTiers)"
        />
      </template>
    </LcTabs>

    <section v-if="content.length">
      <template v-for="item in content" :key="item?.sys.id">
        <h2 v-if="item.caption" class="text-h4 mb-xs">
          {{ item.caption }}
        </h2>

        <ContentfulRenderer class="content" :data="item.content" />
      </template>
    </section>
    <NoResults
      v-else
      :button-text="$t('info.noresults.button')"
      :headline="$t('info.noresults.headline')"
      :subline="$t('info.noresults.subline')"
    />
  </div>
</template>

<script lang="ts" setup>
import type { ContentBlock } from '~/@types/generated/cms/graphql-schema-types'
import type {
  TierTablePrizeTier,
  TierTableProps,
} from '~/components/TierTable/TierTable.vue'

import {
  type ByAddOnSelected,
  type ByMultiplierDeselected,
  type ByMultiplierSelected,
  type ByMultiplierValueMatched,
  type PrizeTier,
  type PrizeTierCategoryRequirements,
  PrizeTierCategoryRequirementsPayloadType,
} from '~/@types/generated/backend/graphql-schema-types'

const hasAddOnName = (
  requirement: PrizeTierCategoryRequirements,
): requirement is
  | ByAddOnSelected
  | ByMultiplierDeselected
  | ByMultiplierSelected
  | ByMultiplierValueMatched =>
  [
    PrizeTierCategoryRequirementsPayloadType.ByAddonSelected,
    PrizeTierCategoryRequirementsPayloadType.ByMultiplierDeselected,
    PrizeTierCategoryRequirementsPayloadType.ByMultiplierSelected,
    PrizeTierCategoryRequirementsPayloadType.ByMultiplierValueMatched,
  ].includes(requirement.type)

const isMultiplierRequirement = (
  requirement: PrizeTierCategoryRequirements,
): requirement is ByMultiplierSelected | ByMultiplierValueMatched =>
  [
    PrizeTierCategoryRequirementsPayloadType.ByMultiplierSelected,
    PrizeTierCategoryRequirementsPayloadType.ByMultiplierValueMatched,
  ].includes(requirement.type)

const props = defineProps<{ id: string }>()

const { t } = useI18n()
let playSlip: Maybe<ReturnType<typeof usePlaySlip>> = null

try {
  playSlip = usePlaySlip()
} catch {
  playSlip = null
}

const { data } = await useContentfulContent().getPrizes(
  `${props.id}/info/prizes`,
)

const addOn = computed(() => {
  if (!playSlip) return null
  const { addOns, afterPurchaseAddOns } = playSlip

  return (
    [...addOns.value, ...afterPurchaseAddOns.value].find(
      (addOn) => !addOn.perPlay,
    ) ?? null
  )
})
const content = computed(
  () => (data.value?.contentBlocksCollection?.items as ContentBlock[]) || [],
)
const prizeTierCategories = computed(
  () => playSlip?.config.value?.prizeTierCategories ?? [],
)
const tabs = computed(() =>
  prizeTierCategories.value
    .filter(({ group }) => group === 'DEFAULT')
    .map(({ categoryRequirements, name, prizeTiers }, index) => ({
      data: {
        addOnName: hasAddOnName(categoryRequirements[0])
          ? categoryRequirements[0].addOnName
          : addOn.value?.name,
        prizeTiers,
        tierMultiplier: tierMultipliers.value[index],
      },
      name,
    })),
)
const tierMultipliers = computed(() =>
  prizeTierCategories.value.reduce<string[]>(
    (acc, { categoryRequirements, name }, index) => {
      if (name === 'DEFAULT') return [...acc, '']

      let values: string[] = []

      if (addOn.value?.options.length) {
        values.push(addOn.value.options[index - 1])
      } else {
        values = categoryRequirements
          .filter(isMultiplierRequirement)
          .map(({ value }) => String(value))
      }

      return [...acc, ...values]
    },
    [],
  ),
)
const tierTableProps = computed<
  Maybe<Omit<TierTableProps, 'groupedPrizeTiers'>>
>(() => {
  if (!playSlip) return null
  const { addOns, licensedTerritory, lotteryId, lotteryType } = playSlip

  return {
    addOns: addOns.value,
    licensedTerritory,
    lotteryId,
    lotteryType: lotteryType.value,
    type: 'prizes',
  }
})

const getGroupedPrizeTiers = (prizeTiers: Omit<PrizeTier, 'odds'>[]) => [
  {
    prizeTiers: prizeTiers.map<TierTablePrizeTier>((prizeTier) => ({
      ...prizeTier,
      prize: prizeTier.amount,
    })),
  },
]

const getTranslatedAddOnTitle = (name?: string) => {
  if (!name || !playSlip) return null
  const { isNumberLottery, isPickLottery, lotteryId } = playSlip

  const addOnTranslation = t(`lottery.${lotteryId}.${name}`)
  if (isNumberLottery.value) return addOnTranslation
  if (isPickLottery.value) {
    return t('info.prizes.tiertab.addon', {
      addOn: addOnTranslation,
    })
  }
  return null
}
</script>

<style lang="scss" scoped>
.info-prizes {
  h4:not(:first-of-type) {
    margin-top: spacing('lg');
  }

  /* stylelint-disable-next-line selector-class-pattern */
  :deep(.lc-tabs__tab) {
    height: 2.375rem;
    padding: 0;
    line-height: $line-height-lg;
  }

  :deep(.content) {
    dl,
    ol,
    ul {
      margin-bottom: spacing('md');
    }

    ol,
    ul {
      padding-left: spacing('md');
      list-style: unset;

      ::marker {
        color: color('secondary600');
      }
    }
  }

  .text-xs {
    font-size: 0.5625rem;
    line-height: $line-height-sm;
  }
}
</style>
